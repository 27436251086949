<template>
    <div class="youtube-player-controls-parent">

        <!-- LISTEN -->
        <button
            class="btn btn-lg btn-success mt-3"
            :class="{ 'btn-danger': playerMuteLocal }"
            @click="onMuteToggle"
        >
            <span v-if="!playerMuteLocal"><i class="fa fa-volume-up mr-1"></i> Listen</span>
            <span v-if="playerMuteLocal"><i class="fa fa-volume-off mr-1"></i> Mute</span>
        </button>

    </div>
</template>

<script>
    // import { mapGetters } from 'vuex';

    export default {
        data() {
            return {
                playerMuteLocal: false
            }
        },
        methods: {
            onMuteToggle() {
                this.playerMuteLocal = !this.playerMuteLocal;
                this.$emit('muted', this.playerMuteLocal);
            },
        }
    }
</script>

<style lang="scss" scoped>

    button {
        pointer-events: auto;
    }

    //---------------------------------------------------------
    //
    //---------------------------------------------------------

</style>